import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import history from '../history';

export const goTo = (urlPath) => {
  history.push(urlPath);
  ReactGA.pageview(urlPath);
  ReactPixel.pageView(urlPath);
};

export const gaInject = () => ReactGA.ga('require', 'ec');

export const trackEvent = (title, category, action) => {
  ReactGA.event({
    category,
    action,
    label: title
  });
};

export const trackEnchanced = (action, { model, title, categoryTitle }) => {
  ReactGA.ga(`ec:${action}`, {
    id: model,
    name: title,
    category: categoryTitle,
    brand: 'The Magic of Bulgarian Rose',
    variant: '1',
    list: categoryTitle,
    position: 1
  });
};

export const pageCtrl = ({ elRef, contRef }) => {
  const setActiveClass = (callback = null) => {
    contRef.current.classList.add('s--el-active');
    if (elRef) elRef.current.classList.add('s--active');
    if (callback !== null) callback();
  };

  const setInactiveClass = (callback = null) => {
    contRef.current.classList.remove('s--el-active');
    if (elRef) elRef.current.classList.remove('s--active');
    if (callback !== null) callback();
  };

  const close = ({ setIsCurrentPage, setLoadState, callback = null }) => {
    goTo('/');
    setInactiveClass();
    if (setIsCurrentPage) setIsCurrentPage(false);
    if (setLoadState) setLoadState(false);
    if (callback) callback();
  }

  const accordion = () => {
    const coll = document.getElementsByClassName('collapsible');
    let i;

    // eslint-disable-next-line no-plusplus
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener('click', (event) => {
        const { target } = event;
        target.classList.toggle('active');
        const content = target.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = `${content.scrollHeight}px`;
        }
      });
    }
  }

  return {
    show: setActiveClass,
    hide: setInactiveClass,
    accordion,
    close,
    goTo
  }
};
