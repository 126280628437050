/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import './ProductDetails.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

import config from '../../../config';
import myDispatch from '../../../store/dispatcher';
import { Head, HideMessenger } from '../..';
import WebpIsSupported from '../../../utils/checkWebp';
import {
  trackEvent, trackEnchanced, gaInject, goTo
} from '../../../utils/common';

function ProductDetails({
  product,
  setLoaded,
  setIsHidden,
  setDetailsLoading,
  innerRef,
  pageSlug
}) {
  const { t } = useTranslation();
  const {
    dispatchProductToCart,
    dispatchQuickmenuVisibility,
    dispatchCloseBtnVisibility
  } = myDispatch();
  const elRef = useRef(null);

  gaInject();
  trackEnchanced('addImpression', { ...product, categoryTitle: product.category_title });

  const images = product.gallery.photos;

  const openBig = () => {};

  const settings = { ...config.SLIDER_SETTINGS, onClickItem: openBig };

  const buyClick = () => {
    trackEvent(product.title, 'Products', 'Add Product to Cart');
    trackEnchanced('addProduct', { ...product, categoryTitle: product.category_title });
    dispatchProductToCart(product);
  };

  const closeClick = (e) => {
    e.stopPropagation();
    dispatchQuickmenuVisibility(true);
    elRef.current.classList.add('hidden');
    setTimeout(() => {
      goTo(`/${pageSlug}`);
      setLoaded(false);
      setIsHidden(false);

      innerRef.current.style = 'overflow-y: scroll';
    }, 3e2);
  };

  const price = (
    <div className="pr__price">
      {`${t('price')} ${product.price} ${t('currency sign')}`}
    </div>
  );

  useEffect(() => {
    dispatchCloseBtnVisibility(false);
    setTimeout(() => {
      setDetailsLoading(false);
      elRef.current.classList.remove('hidden');
    }, 1.2e3);
    return () => {
      dispatchCloseBtnVisibility(true);
    }
  }, []);

  // #region Gallery
  const Image = ({ title, imgSrc }) => <img alt={title} src={imgSrc} width="50px" rel="Button" />

  Image.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
  };

  const GalleryImage = ({ imgSrc, title }) => (
    <>
      {WebpIsSupported() && (
        <picture>
          <source
            srcSet={imgSrc
              .replace('jpeg', 'webp')
              .replace('png', 'webp')
              .replace('jpg', 'webp')}
            type="image/webp"
          />
          <source srcSet={imgSrc} type="image/jpeg" />
          <Image title={title} imgSrc={imgSrc} />
        </picture>
      )}
      {!WebpIsSupported() && <Image title={title} imgSrc={imgSrc} />}
    </>
  );

  GalleryImage.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
  };

  const caroselGallery = (
    <Carousel {...settings}>
      {images.map((image) => {
        const imgSrc = image.image.replace('storage/', '');
        console.log(imgSrc);
        return (
          <div key={`${image.name}`}>
            <GalleryImage title={product.title} imgSrc={imgSrc} />
          </div>
        );
      })}
    </Carousel>
  );
  // #endregion Gallery

  return (
    <>
      <HideMessenger />
      {product && <Head data={product} />}
      <div ref={elRef} className="pr__page hidden">
        <div className="pr__page_banner">
          { caroselGallery }
        </div>
        <div className="pr__page_content">
          <h1 className="pr__title--text">{product.title}</h1>
          <div
            className="pr__text"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
          <div className="price--buy">
            { price }
            <div className="pr__buy">
              <button type="button" onClick={buyClick}>
                {t('I want to own')}
              </button>
            </div>
          </div>
          <div className="pr__close-btn" onClick={closeClick} />
        </div>
      </div>
    </>
  );
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    model: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category_title: PropTypes.string.isRequired,
    gallery: PropTypes.shape({
      photos: PropTypes.array.isRequired
    }).isRequired,
  }).isRequired,
  pageSlug: PropTypes.string.isRequired,
  setLoaded: PropTypes.func.isRequired,
  setIsHidden: PropTypes.func.isRequired,
  setDetailsLoading: PropTypes.func.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
};

export default ProductDetails;
