export const GET_INITIAL_DATA = 'GET_INITIAL_DATA';
export const GET_INITIAL_DATA_SUCCESS = 'GET_INITIAL_DATA_SUCCESS';
export const GET_PRODUCTS_AND_CATEGORIES = 'GET_PRODUCTS_AND_CATEGORIES';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const REMOVE_PRODUCT_TO_CART = 'REMOVE_PRODUCT_TO_CART';
export const PRODUCT_REMOVED_FROM_CART_SUCCESS = 'PRODUCT_REMOVED_FROM_CART_SUCCESS';
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE';
export const CHANGE_CURRENT_PAGE_SUCCESS = 'CHANGE_CURRENT_PAGE_SUCCESS';

export const SHOW_CART_ALERT = 'SHOW_CART_ALERT';
export const HIDE_CART_ALERT = 'HIDE_CART_ALERT';
export const SET_LOADER_VISIBILITY = 'SET_LOADER_VISIBILITY';
export const SET_MESSENGER_VISIBILITY = 'SET_MESSENGER_VISIBILITY';
export const SET_QUICKMENU_VISIBILITY = 'SET_QUICKMENU_VISIBILITY';
export const SET_IMAGE_VISIBILITY = 'SET_IMAGE_VISIBILITY';
export const SET_CLOSE_BTN_VISIBILITY = 'SET_CLOSE_BTN_VISIBILITY';

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST';
export const CONTACT_FORM_SENT_SUCCESSFULLY = 'CONTACT_FORM_SENT_SUCCESSFULLY';
export const CONTACT_FORM_SENT_FAILED = 'CONTACT_FORM_SENT_FAILED';
export const REQUEST_FORM_REQUEST = 'REQUEST_FORM_REQUEST';
export const REQUEST_FORM_SENT_SUCCESSFULLY = 'REQUEST_FORM_SENT_SUCCESSFULLY';
export const REQUEST_FORM_SENT_FAILED = 'REQUEST_FORM_SENT_FAILED';
