import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();
export default [sagaMiddleware];

// export const logger = (store) => (next) => (action) => {
//   console.group(action.type);
//   console.info('PREV STATE', store.getState());
//   const result = next(action);
//   console.info('NEXT STATE', store.getState());
//   console.groupEnd();
//   return result;
// };

// export default [sagaMiddleware, logger];
