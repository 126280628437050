import config from '../config';

const {
  LANGUAGE,
} = config;

const flattenObject = (ob, skipThis = [], dontConvert = []) => {
  const toReturn = {};
  Object.keys(ob).forEach((key) => {
    if (skipThis.length && skipThis.includes(key)) return;

    if (!Object.prototype.hasOwnProperty.call(ob, key)) return;

    if (key === 'defaultBanner' || key === 'mobileBanner') ob[key] = ob[key].replace('storage/', '');

    if ((typeof ob[key]) === 'object' && ob[key] !== null) {
      const flatObject = flattenObject(ob[key]);
      if (dontConvert.includes(key)) {
        toReturn[key] = ob[key];
        return;
      }

      if (Object.prototype.hasOwnProperty.call(flatObject, LANGUAGE)) {
        toReturn[`${key}`] = ob[key][LANGUAGE];
      } else {
        Object.keys(flatObject).forEach((x) => {
          if (!Object.prototype.hasOwnProperty.call(flatObject, x)) return;
          toReturn[`${key}_${x}`] = flatObject[x];
        })
      }
    } else {
      toReturn[key] = ob[key];
    }
  });
  return toReturn;
};

export default flattenObject;
