import './Cart.css';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import myDispatch from '../../store/dispatcher';
import { Textarea, Input } from '..';
// import ReactGA from 'react-ga';

function Cart() {
  const { dispatchRequestFormRequest } = myDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'firstError'
  });
  const products = useSelector((selector) => selector.cart.products);
  const [names, setNames] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [agree, setAgree] = useState(false);
  const [content, setContent] = useState('');
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();

  const onSubmit = () => {
    dispatchRequestFormRequest({
      names,
      phone,
      email,
      content,
      products
    });
    setNames('');
    setPhone('');
    setEmail('');
    setContent('');
    setIsSent(true);
    setTimeout(() => {
      setIsSent(false);
    }, 5e3);
  };

  return (
    <>
      {isSent && <p style={{ paddingTop: '10px', color: 'green' }}>{t('thanks for the request')}</p>}
      <form className="html-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          translation="name"
          type="text"
          value={names}
          disabled={isSent}
          name="names"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setNames}
          errors={errors}
        />
        <Input
          translation="phone"
          type="text"
          value={phone}
          disabled={isSent}
          name="phone"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setPhone}
          errors={errors}
        />
        <Input
          translation="email"
          type="text"
          value={email}
          disabled={isSent}
          name="email"
          refReg={register({ required: true, pattern: /^\S+@\S+$/i })}
          onChange={setEmail}
          errors={errors}
        />
        <Textarea
          translation="address"
          value={content}
          disabled={isSent}
          name="address"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setContent}
          errors={errors}
        />
        <Input
          translation="agree order"
          type="checkbox"
          value={agree}
          disabled={isSent}
          name="agree"
          refReg={register({ required: true })}
          onChange={setAgree}
          errors={errors}
          right
        />
        <input type="submit" disabled={isSent} value={t('send')} />
      </form>
    </>
  );
}

export default Cart;
