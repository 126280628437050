/* eslint-disable import/prefer-default-export */
import {
  GET_INITIAL_DATA_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  ADD_PRODUCT_TO_CART_SUCCESS,
  PRODUCT_REMOVED_FROM_CART_SUCCESS,
  CONTACT_FORM_SENT_SUCCESSFULLY,
  HIDE_CART_ALERT,
  CONTACT_FORM_SENT_FAILED,
  REQUEST_FORM_SENT_SUCCESSFULLY,
  REQUEST_FORM_SENT_FAILED,
  GET_INITIAL_DATA,
  GET_PRODUCTS_AND_CATEGORIES,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_TO_CART,
  CONTACT_FORM_REQUEST,
  REQUEST_FORM_REQUEST,
  SET_LOADER_VISIBILITY,
  SET_MESSENGER_VISIBILITY,
  SET_QUICKMENU_VISIBILITY,
  SET_IMAGE_VISIBILITY,
  SET_CLOSE_BTN_VISIBILITY,
  SHOW_CART_ALERT
} from '../action-types';

export const pageReceivedSuccessfully = (payload) => ({
  type: GET_INITIAL_DATA_SUCCESS,
  payload
});

export const productsReceivedSuccessfully = (payload) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload
});

export const categoriesReceivedSuccessfully = (payload) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload
});

export const productAddedToCartSuccessfully = (payload) => ({
  type: ADD_PRODUCT_TO_CART_SUCCESS,
  payload
});

export const productRemovedFromCartSuccessfully = (payload) => ({
  type: PRODUCT_REMOVED_FROM_CART_SUCCESS,
  payload
});

export const setLoadingSuccessfully = (payload) => ({
  type: SET_LOADER_VISIBILITY,
  payload
});

export const showCartAlertsSuccessfully = () => ({ type: SHOW_CART_ALERT });

export const hideCartAlertsSuccessfully = () => ({ type: HIDE_CART_ALERT });

export const getCurrentPage = (payload = 'home') => ({
  type: GET_INITIAL_DATA,
  payload
});

export const getProductsRequest = () => ({ type: GET_PRODUCTS_AND_CATEGORIES });

export const addProductToCart = (payload) => ({
  type: ADD_PRODUCT_TO_CART,
  payload
})

export const removeProductFromCart = (payload) => ({
  type: REMOVE_PRODUCT_TO_CART,
  payload
});

export const sendContactForm = (payload) => ({
  type: CONTACT_FORM_REQUEST,
  payload
});

export const contactFormSentSuccessfully = (payload) => ({
  type: CONTACT_FORM_SENT_SUCCESSFULLY,
  payload
});

export const contactFormSentFailed = (payload) => ({
  type: CONTACT_FORM_SENT_FAILED,
  payload
});

export const requestFormRequest = (payload) => ({
  type: REQUEST_FORM_REQUEST,
  payload
});

export const requestFormSentSuccessfully = (payload) => ({
  type: REQUEST_FORM_SENT_SUCCESSFULLY,
  payload
});

export const requestFormSentFailed = (payload) => ({
  type: REQUEST_FORM_SENT_FAILED,
  payload
});

export const setMessengerVisibility = (payload) => ({
  type: SET_MESSENGER_VISIBILITY,
  payload
});

export const setQuickmenuVisibility = (payload) => ({
  type: SET_QUICKMENU_VISIBILITY,
  payload
});

export const setImageVisibility = (payload) => ({
  type: SET_IMAGE_VISIBILITY,
  payload
});

export const setCloseBtnVisibility = (payload) => ({
  type: SET_CLOSE_BTN_VISIBILITY,
  payload
});
