import React from 'react';

import config from '../../config';

const {
  LANGUAGE,
  LANGUAGES
} = config;

function LanguageSwitcher() {
  return (
    <div className="language-switcher">
      {LANGUAGE === 'bg' && <a href={`//${LANGUAGES.en.domain}`}>{LANGUAGES.en.title}</a>}
      {LANGUAGE === 'en' && <a href={`//${LANGUAGES.bg.domain}`}>{LANGUAGES.bg.title}</a>}
    </div>
  );
}

LanguageSwitcher.propTypes = {};

export default LanguageSwitcher;
