import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import * as serviceWorker from './serviceWorker';

import './index.css';

import store, { persistor } from './store';
import saga from './store/sagas';
import { sagaMiddleware } from './store/middleware';
import Home from './pages/Home';
import history from './history';
import config from './config';
import routes from './routes';
import { MyProvider } from './utils/Context';
import './i18n';

const {
  GOOGLE_ANALYTICS,
  FB_APPID,
  FB_PAGEID,
  FB_PIXEL_ID
} = config;

ReactPixel.init(FB_PIXEL_ID, {}, { debug: true, autoConfig: false });

const location = window.location.pathname + window.location.search;

ReactGA.initialize(GOOGLE_ANALYTICS);

function App() {
  useEffect(() => {
    ReactGA.pageview(location);
    ReactPixel.pageView();
  });

  return (
    <MyProvider>
      <MessengerCustomerChat pageId={FB_PAGEID} appId={FB_APPID} />
      <Router history={history}>
        <Route exact path={routes.HOME}>
          <Home />
        </Route>
        <Route exact path={routes.PAGE}>
          <Home />
        </Route>
        <Route path={routes.PRODUCT}>
          <Home />
        </Route>
      </Router>
    </MyProvider>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

sagaMiddleware.run(saga);
serviceWorker.unregister();
