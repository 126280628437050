import './ContactForm.css';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import myDispatch from '../../store/dispatcher';
import config from '../../config';
import { Input, Textarea } from '..';

const { NOTIFICATION_TIMEOUT } = config;

function ContactForm() {
  const { dispatchContactForm } = myDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    submitFocusError: true,
    validateCriteriaMode: 'firstError'
  });
  const [names, setNames] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [agree, setAgree] = useState(false);
  const [content, setContent] = useState('');
  const [isSent, setIsSent] = useState(false);
  const { t } = useTranslation();

  const onSubmit = () => {
    dispatchContactForm({
      names,
      phone,
      email,
      content
    });
    setNames('');
    setPhone('');
    setEmail('');
    setAgree(false);
    setContent('');
    setIsSent(true);
    setTimeout(() => {
      setIsSent(false);
    }, NOTIFICATION_TIMEOUT);
  };

  return (
    <>
      {isSent && <p>{t('thanks for the message')}</p>}
      <form className="html-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          translation="name"
          type="text"
          value={names}
          disabled={isSent}
          name="names"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setNames}
          errors={errors}
        />
        <Input
          translation="phone"
          type="text"
          value={phone}
          disabled={isSent}
          name="phone"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setPhone}
          errors={errors}
        />
        <Input
          translation="email"
          type="text"
          value={email}
          disabled={isSent}
          name="email"
          refReg={register({ required: true, pattern: /^\S+@\S+$/i })}
          onChange={setEmail}
          errors={errors}
        />
        <Textarea
          translation="message"
          value={content}
          disabled={isSent}
          name="content"
          refReg={register({ required: true, min: 3, minLength: 0 })}
          onChange={setContent}
          errors={errors}
        />
        <Input
          translation="agree"
          type="checkbox"
          value={agree}
          disabled={isSent}
          name="agree"
          refReg={register({ required: true })}
          onChange={setAgree}
          errors={errors}
          right
        />
        <input type="submit" disabled={isSent} value={t('send')} />
      </form>
    </>
  );
}

export default ContactForm;
