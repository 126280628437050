import React from 'react';
import PropTypes from 'prop-types';

import WebpIsSupported from '../../utils/checkWebp';

function PageBackground({ banner }) {
  return (
    <div
      className="el__bg"
      style={{
        backgroundImage:
          WebpIsSupported() ? `url(${banner.replace('jpeg', 'webp')})` : `url(${banner})`,
        backgroundSize: 'cover'
      }}
    />
  );
}

PageBackground.propTypes = {
  banner: PropTypes.string.isRequired
};

export default PageBackground;
