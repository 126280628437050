/* eslint-disable react/no-array-index-key */
import './ProductDetailsLoading.css';

import React from 'react';

function ProductDetailsLoading() {
  return (
    <div className="pr__page pr_el_loading show">
      <div className="pr__page_banner animated-background" />
      <div className="pr__page_content">
        <div className="text">
          {[...Array(9)].map((item, _i) => (
            <div key={_i} className="text-line animated-background" />
          ))}
        </div>
      </div>
    </div>
  );
}
export default ProductDetailsLoading;
