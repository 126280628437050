import { useDispatch } from 'react-redux';
import {
  removeProductFromCart,
  getCurrentPage,
  getProductsRequest,
  addProductToCart,
  sendContactForm,
  requestFormRequest,
  setLoadingSuccessfully,
  setMessengerVisibility,
  setQuickmenuVisibility,
  setImageVisibility,
  setCloseBtnVisibility
} from './actions';

const Dispatch = () => {
  const dispatch = useDispatch();

  const dispatchRemoveProductFromCart = (payload) => {
    dispatch(removeProductFromCart(payload));
  };

  const loader = (payload) => {
    dispatch(setLoadingSuccessfully(payload));
  }

  const dispatchHomePage = () => {
    dispatch(getCurrentPage());
  };

  const dispatchProductsPage = () => {
    dispatch(getProductsRequest());
  };

  const dispatchProductDetails = (payload) => {
    dispatch(addProductToCart(payload));
  };

  const dispatchContactForm = (payload) => {
    dispatch(sendContactForm(payload));
  };

  const dispatchRequestFormRequest = (payload) => {
    dispatch(requestFormRequest(payload));
  };

  const dispatchMessengerVisibility = (payload) => {
    dispatch(setMessengerVisibility(payload));
  };

  const dispatchQuickmenuVisibility = (payload) => {
    dispatch(setQuickmenuVisibility(payload));
  };

  const dispatchImageVisibility = (payload) => {
    dispatch(setImageVisibility(payload));
  };

  const dispatchCloseBtnVisibility = (payload) => {
    dispatch(setCloseBtnVisibility(payload));
  };

  const dispatchProductToCart = (action) => {
    dispatch(addProductToCart(action));
  }

  return {
    dispatchHomePage,
    dispatchProductsPage,
    dispatchRemoveProductFromCart,
    dispatchProductDetails,
    dispatchContactForm,
    dispatchRequestFormRequest,
    dispatchMessengerVisibility,
    dispatchQuickmenuVisibility,
    dispatchImageVisibility,
    dispatchCloseBtnVisibility,
    dispatchProductToCart,
    loader
  };
}

export default Dispatch;
