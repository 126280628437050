import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const Input = ({
  translation, type, value, disabled, name, refReg, onChange, errors, right = false
}) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={name}>
      {!right ? t(translation) : ''}
      <input
        type={type}
        placeholder={t(translation)}
        value={value}
        disabled={disabled}
        name={name}
        id={name}
        className={errors[name] ? 'error' : ''}
        ref={refReg}
        onChange={(e) => onChange(e.target.value)}
      />
      {right ? t(translation) : ''}
      {errors[name] && (<span className="form-error">{t('field is required')}</span>)}
    </label>
  )
};

Input.propTypes = {
  translation: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  right: PropTypes.bool,
  name: PropTypes.string.isRequired,
  refReg: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]).isRequired
};

export default Input;
