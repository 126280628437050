export default {
  GOOGLE_ANALYTICS: 'UA-159452718-1',
  FB_PIXEL_ID: '3228121923968989',
  FB_PAGEID: '864577240375572',
  FB_APPID: '194975758546896',
  API_URL: 'https://admin.bulgarianrosemagic.com/api/',
  LANGUAGE: 'bg',
  DEFAULT_LANG: 'bg',
  NOTIFICATION_TIMEOUT: 5000,
  LOADING_SCREEN_IMAGE: 'https://cdn.bulgarianrosemagic.com/obshi/rVjkIugUUILa9kaENXH8CrZi7Dksg3igbrSQVmeu.png',
  SLIDER_SETTINGS: {
    showThumbs: false,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    interval: 3000,
    stopOnHover: true
  },
  LANGUAGES: {
    bg: {
      title: 'БГ',
      domain: 'www.bulgarianrosemagic.com',
      default_category: 'energiya-v-bizhu'
    },
    en: {
      title: 'EN',
      domain: 'en.bulgarianrosemagic.com',
      default_category: 'energy-in-jewelry'
    }
  },
};
