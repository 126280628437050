/* eslint-disable no-case-declarations */
import {
  GET_INITIAL_DATA_SUCCESS,
  GET_INITIAL_DATA,
  GET_PRODUCTS_AND_CATEGORIES,
  GET_PRODUCTS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  CHANGE_CURRENT_PAGE_SUCCESS,
  CHANGE_CURRENT_PAGE,
} from '../action-types';

const dataReducer = (
  state = {
    current: {},
    pages: [],
    products: [],
    categories: [],
  },
  action
) => {
  switch (action.type) {
    case CHANGE_CURRENT_PAGE:
      return { ...state };
    case CHANGE_CURRENT_PAGE_SUCCESS:
      return { ...state, pages: action.payload };
    case GET_INITIAL_DATA:
      return { ...state };
    case GET_INITIAL_DATA_SUCCESS:
      return { ...state, pages: action.payload };
    case GET_PRODUCTS_AND_CATEGORIES:
      return { ...state };
    case GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};
export default dataReducer;
