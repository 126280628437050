import './ProductCard.css';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import WebpIsSupported from '../../../utils/checkWebp';

function ProductCard({
  product,
  isImageLoading,
  isHidden,
  prRef,
  prClick,
  handleImageLoad,
  handleImageError
}) {
  const imgRef = useRef();

  const status = product.status !== 1 ? 'Out of stock' : 'In stock';
  const classToUse = `pr_el ${product.status !== 1 ? 'inactive' : ''}${isImageLoading || isHidden ? ' hidden' : ''}`;

  const image = (
    <img
      ref={imgRef}
      alt={product.title}
      src={product.defaultBanner}
      onLoad={handleImageLoad}
      onError={handleImageError}
    />
  );

  const imageShow = (
    <>
      {WebpIsSupported() && (
        <picture>
          <source
            srcSet={product.defaultBanner.replace('jpeg', 'webp')
              .replace('png', 'webp')
              .replace('jpg', 'webp')}
            type="image/webp"
          />
          <source srcSet={product.defaultBanner} type="image/jpeg" />
          { image }
        </picture>
      )}
      {!WebpIsSupported() && <>{ image }</>}
    </>
  )

  return (
    <div
      className={classToUse}
      ref={prRef}
      onClick={prClick}
    >
      <div className="pr_banner">
        <div className="pr_status">
          { status }
        </div>
        { imageShow }
      </div>
      <div className="pr_title">{product.title}</div>
      <div className="pr_category">{product.category_title}</div>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    defaultBanner: PropTypes.string.isRequired,
    category_title: PropTypes.string.isRequired,
  }).isRequired,
  handleImageLoad: PropTypes.func.isRequired,
  handleImageError: PropTypes.func.isRequired,
  prClick: PropTypes.func.isRequired,
  isImageLoading: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  prRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
};

export default ProductCard;
