/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import ImageIcon from '@material-ui/icons/Image';

import myDispatch from '../../store/dispatcher';
import { CloseButton } from '..';

function PageContent({
  title, content, handleClose, refAnimation
}) {
  const imageVisible = useSelector((state) => state.ui.imageVisible);
  const { dispatchImageVisibility } = myDispatch();

  const showHideImage = () => {
    ReactGA.event({
      category: 'Page',
      action: 'Open Image',
      label: title
    });
    dispatchImageVisibility(true);
  };

  return (
    <div className={`el__content ${imageVisible ? 'hidden' : ''}`}>
      <div ref={refAnimation} className="el__text">
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <span className="view-image" onClick={showHideImage}>
        <ImageIcon />
      </span>
      <CloseButton handleClose={handleClose} />
    </div>
  );
}

PageContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  refAnimation: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
};

export default PageContent;
