import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';
import middleware from './middleware';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui']
};

const composeEnchancers = composeWithDevTools(applyMiddleware(...middleware));


const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeEnchancers
);

export const persistor = persistStore(store);

export default store;
