import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, bg, ru } from './locales';
import config from './config';

const { LANGUAGE } = config;

i18n
  .use(initReactI18next)
  .init({
    resources: { en, bg, ru },
    lng: LANGUAGE,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
