import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

function Head({ data }) {
  const title = `${data.title} - The Bulgarian Rose Magic`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        { title }
      </title>
      <link rel="canonical" href={window.location.href} />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="title" content={data.seo_title} />
      <meta name="robots" content={data.seo_follow_type} />
      <meta name="description" content={data.seo_description} />
      <meta name="keywords" content={data.seo_keywords} />
      <meta property="og:type" content="webpage" />
      <meta property="og:title" content={data.seo_title} />
      <meta property="og:description" content={data.seo_description} />
      <meta property="og:image" content={data.defaultBanner} />
      <meta property="og:url" content={data.slug} />
      <meta property="og:site_name" content="The Bulgarian Rose Magic" />
      <meta name="twitter:title" content={data.seo_title} />
      <meta name="twitter:description" content={data.seo_description} />
      <meta name="twitter:image" content={data.defaultBanner} />
    </Helmet>
  );
}

Head.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired,
    seo_title: PropTypes.string.isRequired,
    seo_description: PropTypes.string,
    seo_follow_type: PropTypes.string.isRequired,
    seo_keywords: PropTypes.string.isRequired
  }).isRequired
};

export default Head;
