import React from 'react';
import { useSelector } from 'react-redux';

import config from '../../config';

function LoadingScreen() {
  const showLoader = useSelector((selector) => selector.ui.loading);

  return (
    <>
      {showLoader && (
        <div className="loading-screen">
          <div className="triple-spinner" />
          <img
            alt="logo"
            className="animation-logo"
            src={config.LOADING_SCREEN_IMAGE}
          />
        </div>
      )}
    </>
  );
}

LoadingScreen.propTypes = {};

export default LoadingScreen;
