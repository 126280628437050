import React from 'react';

function HideMessenger() {
  return (
    <style>
      {'.fb_dialog {\
        display: none!important;\
      }'}
    </style>
  );
}

HideMessenger.propTypes = {};

export default HideMessenger;
