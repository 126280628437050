import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function CloseButton({ handleClose }) {
  const closeBtnVisible = useSelector((state) => state.ui.closeBtnVisible);
  const myClose = (e) => {
    e.stopPropagation();
    handleClose();
  }

  return (
    <>
      {closeBtnVisible
        && (
        <div
          className="el__close-btn"
          onClick={myClose}
        />
        )}
    </>
  );
}

CloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default CloseButton;
