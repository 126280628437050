import './Dropdown.css';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  trackEvent
} from '../../utils/common';

const Dropdown = ({ filter, setFilter }) => {
  const [displayMenu, setDisplayMenu] = useState();
  const [title, setTitle] = useState();
  const categories = useSelector((state) => state.data.categories);

  const showDropdownMenu = (event) => {
    event.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  const analyticsEvent = (category) => {
    trackEvent(category, 'Products', 'Change Category');
  };

  useEffect(() => {
    const cat = categories.find((c) => c.slug === filter);
    if (cat) {
      setTitle(cat.title);
    }
  }, [filter]);

  return (
    <div className="dropdown" style={{ width: '280px' }}>
      <div className="button" onClick={showDropdownMenu}>
        {title}
      </div>

      {displayMenu ? (
        <ul>
          {categories.map((cat) => (
            <li
              onClick={() => {
                analyticsEvent(cat.title);
                setFilter(cat.slug);
                setDisplayMenu(!displayMenu);
              }}
              key={cat.slug}
            >
              <div>{cat.title}</div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

Dropdown.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired
};

export default Dropdown;
