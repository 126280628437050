/* eslint-disable react/no-array-index-key */
import './ProductLoading.css';

import React from 'react';
import PropTypes from 'prop-types';

function ProductLoading({ isHidden }) {
  return (
    <div className={`pr_el pr_el_loading${isHidden ? ' hidden' : ''}`}>
      <div className="image animated-background" />
      <div className="text">
        {[...Array(2)].map((item, _i) => (
          <div key={_i} className="text-line animated-background" />
        ))}
      </div>
    </div>
  );
}

ProductLoading.propTypes = {
  isHidden: PropTypes.bool.isRequired
};
export default ProductLoading;
