/* eslint-disable no-console */
import './Home.css';

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import myDispatch from '../../store/dispatcher';
import {
  Contacts,
  CartContainer,
  Products,
  Sections
} from '../../containers';
import {
  Head,
  SpeedDial,
  LanguageSwitcher,
  LoadingScreen,
  CartNotification,
} from '../../components';

function Home() {
  const contRef = useRef(null);
  const { dispatchHomePage, loader } = myDispatch();
  const pages = useSelector((state) => state.data.pages);
  const cartUpdated = useSelector((state) => state.cart.cartUpdated);

  useEffect(() => {
    dispatchHomePage();
    const t1 = setTimeout(() => {
      loader(false);
      contRef.current.classList.remove('s--inactive');
    }, 6e2);
    return () => {
      clearTimeout(t1);
    }
  }, [cartUpdated]);

  const getSections = (
    <>
      {pages
          && pages.map((p, i) => {
            if (i === 0 || i === 4 || i === 5 || i === 6) return;
            return <Sections key={p.id} page={p} contRef={contRef} />;
          })}
    </>
  );

  const getProductPage = (
    <>
      {pages && pages[4] && (
        <Products contRef={contRef} page={pages[4]} />
      )}
    </>
  );

  const getContactsPage = (
    <>
      {pages && pages[5] && (
        <Contacts contRef={contRef} page={pages[5]} />
      )}
    </>
  );

  const getCartPage = (
    <>
      {pages && pages[6] && (
        <CartContainer contRef={contRef} page={pages[6]} />
      )}
    </>
  )

  return (
    <div className="cont s--inactive" ref={contRef}>
      <LoadingScreen />
      {pages[0] && <Head data={pages[0]} />}
      <LanguageSwitcher />
      <div className="cont__inner">
        { getSections }
        { getProductPage }
        { getContactsPage }
        { getCartPage }
        <SpeedDial />
      </div>
      <CartNotification />
    </div>
  );
}
export default Home;
